import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// Import data and settings
import {
  startingLocation,
  startingZoomLevel,
  GOOGLE_API_KEY,
  componentMode,
} from "./imports/data";

// Import Estimator component
import Estimator from "./estimator/estimator";
import { MeasurItTitleBar } from "./interface/titlebar";
import { useMediaQuery } from "@mui/material";
import json2mq from "json2mq";
import { shortQueries } from "./queries";
import useDebounce from "./hooks/useDebounce";
import WelcomeTour from "./components/tours/WelcomeTour";
import ColorGuideTour from "./components/tours/ColorGuideTour";
/*
  This component serves as the controlling parent for
  the "dumb" estimator component.  Top-level functionality
  and state will be stored here and passed into the 
  subcomponents.
*/

const theme = createTheme({
  typography: {
    fontFamily: ["Open Sans", "sans-serif"].join(","),
  },
});

export default function App() {
  // Set initial state
  const [mode, setMode] = useState(componentMode);
  const [mapCenter, setMapCenter] = useState(startingLocation);
  const debouncePlaceLookup = useDebounce(mapCenter, 1000);
  const [mapBounds, setMapBounds] = useState(null);
  const [coverageSquareArea, setCoverageSquareArea] = useState(0);
  const [drawnLinearDistance, setDrawnLinearDistance] = useState(0);
  const [address, setAddress] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [tourOpen, setTourOpen] = useState(true);
  const [drawTourOpen, setDrawTourOpen] = useState(false);

  const [saving, setSaving] = useState(false);

  const allowedMethods = {
    setLatLng: ({ lat, lng }) => {
      alert(`Lat: ${lat}, Lng: ${lng}`);
    },
    setAddressString: ({ address }) => {
      setLoaded(false);
      setAddress(address);
      const fetchUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${GOOGLE_API_KEY}`;
      fetch(fetchUrl)
        .then(async (response) => {
          try {
            if (response.status === 200) {
              const body = await response.json();
              if (body.status === "OK") {
                if (body.results.length) {
                  const first = body.results[0];
                  setMapCenter(first.geometry.location);
                  setTimeout(() => {
                    setLoaded(true);
                  }, 500);
                } else {
                  console.log("no results");
                }
              } else {
                console.log("status is not good");
              }
            } else {
              console.log("bad request");
            }
          } catch (e) {
            console.log("body parsing error", e);
          }
        })
        .catch((e) => {
          console.log("geo error", e);
        });
    },
  };

  if (window.addEventListener) {
    window.addEventListener("message", onMessage, false);
  } else if (window.attachEvent) {
    window.attachEvent("onmessage", onMessage, false);
  }

  function onMessage(event) {
    const data = event.data;
    const func = allowedMethods[data.func];
    if (func) {
      if (typeof func === "function") {
        func(data.args);
      }
    }
  }

  const preloadImages = (url) => {
    const img = new Image();
    img.src = url;
  };

  // preload images
  [
    "/measur.it-intro.png",
    "/Measureit-logo.png",
    "/measurit-pan-zoom.gif",
    "/measur.it-draw-undo.gif",
  ].map(preloadImages);

  // FIXME
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!loaded) {
      setTimeout(() => {
        setLoaded(true);
      }, 1000);
    }
    if (loaded) {
      const placeNearbyUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${debouncePlaceLookup.lat},${debouncePlaceLookup.lng}&key=${GOOGLE_API_KEY}`;
      fetch(placeNearbyUrl, {})
        .then(async (response) => {
          try {
            const body = await response.json();
            if (body.status === "OK") {
              const records = body.results || [];
              const roofs = records.filter(
                (i) => i.geometry.location_type === "ROOFTOP",
              );
              if (roofs.length) {
                const first = roofs[0];
                const address = first.formatted_address;
                window.parent.postMessage(
                  {
                    type: "address_change",
                    value: address,
                  },
                  "*",
                );
              } else {
                console.log("no results");
              }
            } else {
              console.log("status is not good");
            }
          } catch (e) {
            console.log("body parsing error", e);
          }
        })
        .catch((e) => {
          console.log("geo error", e);
        });
    }
  }, [debouncePlaceLookup]);

  // Acquire window dimensions
  // IMPORTANT: the estimator is NOT responsive to changes
  // to the window width and height
  const windowDimensions = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  // SET OPTIONS

  // Map image parameters (number values)
  // These may be dictated by limitations within Google Maps Static API
  // Pay-As-You-Go max values are 640;
  // Google Maps Platform Premium Plan gets you 2048
  // https://developers.google.com/maps/documentation/maps-static/usage-and-billing
  const mapHeight =
    windowDimensions.height <= 640 ? windowDimensions.height : 940;
  const mapWidth = windowDimensions.width <= 640 ? windowDimensions.width : 940;

  // Specify colors for painting tool and buttons
  // Values can be any valid color name, hex value, or rgba
  // e.g. "green", "#00ff00", rgba(0,255,0,1.0)
  // Specify value of color for painting tool
  // NOTE: Use of transparent color is not recommended for paint
  // Required keys:  paint, iconActive, iconInactive, button
  const color = {
    paint: "rgba(43, 227, 150)",
    iconActive: "#1565C0",
    iconInactive: "#757575",
    button: "whitesmoke",
  };

  // Specify radius values of brushes
  // Radius value is number of pixels (e.g. 20)
  // Required keys: tiny, small, medium, and large
  const brushSize = {
    tiny: 3, // Specifically used for perimeter measurement
    small: 6,
    medium: 12,
    large: 18,
  };

  // Build an optional object of styles to pass in to the estimator
  // These styles affect the DIV wrapping the estimator component
  // IMPORTANT: mapHeight and mapWidth passed in will override any
  // height and width values passed in via styles
  const styles = {
    // Add custom styles here
  };

  // BUILD CALLBACKS

  // Build function to update the estimator mode
  // For dev purposes only
  const updateMode = (mode) => {
    setMode(mode);
  };

  // Build function to update map center
  // Takes LatLng object as argument
  const updateMapCenter = (latLng) => {
    // console.log("Moved center of map:", latLng);
    // Update state
    setMapCenter(latLng);
  };

  // Build function to update map bounds
  // Takes an object as an argument with the following structure:
  // { sw: LatLng Object, ne: LatLng Object }
  const updateMapBounds = (data) => {
    // console.log("Here are the map bounds:", data);
    // Save data to state
    setMapBounds(data);
  };

  // Build function to update canvas coverage square area
  // Takes a numeric value as an argument
  const updateCoverageSquareArea = (value) => {
    // Update state
    setCoverageSquareArea(value);
  };

  // Build function to update linear distance drawn on canvas
  // Takes a numeric value as an argument
  const updateDrawnLinearDistance = (value) => {
    // Update state
    setDrawnLinearDistance(value);
  };

  // Build function to save the current HTML canvas drawing
  // Takes a base64 jpeg image as an argument
  const saveDrawing = async (image, original) => {
    // console.log("Saved image:", image);
    // The below can be used to retrieve images without sending them to S3
    // downloadImage(image, "measurit-image.jpg");
    setSaving(true);

    const meta = await uploadImage(image);
    const originalmeta = await uploadImage(original);

    const aiPayload = {
      dataset: {
        originalUrl: originalmeta.Location,
        measuredUrl: meta.Location,
        mapBounds,
        mapCenter,
        address,
        mode,
        estimate: mode === "area" ? coverageSquareArea : drawnLinearDistance,
      },
    };

    await uploadAIDataset(aiPayload);

    window.parent.postMessage(
      {
        type: "estimate",
        value: mode === "area" ? coverageSquareArea : drawnLinearDistance,
        mode,
        image,
        meta,
      },
      "*",
    );

    setSaving(false);
  };

  const uploadAIDataset = async (data) => {
    const url = `${process.env.REACT_APP_MEASURIT_API_BASE_URL}/estimates/datasets`;
    console.log(`Uploading AI dataset to ${url}`);

    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *client
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });

    const meta = await response.json();
    return meta;
  };

  const uploadImage = async (image) => {
    const url = `${process.env.REACT_APP_MEASURIT_API_BASE_URL}/estimates/upload`;
    console.log(`Uploading image to ${url}`);

    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *client
      body: JSON.stringify({ image }), // body data type must match "Content-Type" header
    });

    const meta = await response.json();
    return meta;
  };

  const short = useMediaQuery(json2mq(shortQueries));

  const containerStyle = {
    // display: 'inline-grid',
    // alignContent: 'center',
    // backgroundColor: '#ccc',
    // height: '100vh',
    // verticalAlign: 'middle'
    overflow: "hidden",
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="measurit-container" style={containerStyle}>
        <MeasurItTitleBar
          measurement={
            mode === "area" ? coverageSquareArea : drawnLinearDistance
          }
          mode={mode}
        />
        <WelcomeTour open={tourOpen} onClose={() => setTourOpen(false)} />
        <ColorGuideTour
          open={drawTourOpen}
          onClose={() => setDrawTourOpen(false)}
        />

        {!loaded ? null : (
          <Estimator
            setDrawTourOpen={setDrawTourOpen}
            mode={mode}
            updateMode={updateMode} // Dev purposes only
            GOOGLE_API_KEY={GOOGLE_API_KEY}
            mapHeight={mapHeight}
            mapWidth={mapWidth}
            style={styles}
            mapCenter={mapCenter}
            updateMapCenter={updateMapCenter}
            startingZoomLevel={
              short ? startingZoomLevel - 1 : startingZoomLevel
            }
            updateMapBounds={updateMapBounds}
            color={color}
            brushSize={brushSize}
            updateCoverageSquareArea={updateCoverageSquareArea}
            updateDrawnLinearDistance={updateDrawnLinearDistance}
            saveDrawing={saveDrawing}
            className="estimator"
            measurement={
              mode === "area" ? coverageSquareArea : drawnLinearDistance
            }
          />
        )}

        <Backdrop
          style={{ zIndex: 1001, color: "#fff" }}
          open={saving}
          onClick={() => {}}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </ThemeProvider>
  );
}
